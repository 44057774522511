import request from '@/utils/scm-request'

export function redundantStockProvisionPage(params, data) {
  return request({
    url: `scm-purchase/redundantStockProvision/page`,
    method: 'post',
    params,
    data
  })
}

// 导出
export function redundantStockProvisionExport(data) {
  return request({
    url: `scm-purchase/redundantStockProvision/export`,
    method: 'post',
    data
  })
}

// 导出明细
export function redundantStockProvisionExportDetail(data) {
  return request({
    url: `scm-purchase/redundantStockProvision/exportCanProvisionDetail`,
    method: 'post',
    data
  })
}

// 生成调拨单预览
export function createTransferOrderView(data) {
  return request({
    url: `scm-purchase/redundantStockProvision/createTransferOrderView`,
    method: 'post',
    data
  })
}

// 库存调配预览
export function provisionView(data) {
  return request({
    url: `scm-purchase/redundantStockProvision/provisionView`,
    method: 'post',
    data
  })
}

// 提交
export function createTransferOrderSubmit(data) {
  return request({
    url: `scm-purchase/redundantStockProvision/createTransferOrderSubmit`,
    method: 'post',
    data
  })
}

// 库存调配建议预览
export function provisionSuggestView(data) {
  return request({
    url: `scm-purchase/redundantStockProvision/provisionSuggestView`,
    method: 'post',
    data
  })
}
