export const allocatColumns = [
  { label: '序号', slot: 'newIndex' },
  { label: 'Style', prop: 'styleName' },
  { label: 'Color', prop: 'color' },
  { label: 'Size', prop: 'size' },
  { label: 'Sku', prop: 'sku' },
  { label: '调入备货渠道', prop: 'inStockChannelName' },
  { label: 'TurnOver(天)', prop: 'turnover' },
  { label: '库存汇总', prop: 'totalStock' },
  { label: '预测销售数量', prop: 'fcstNumber' },
  { label: '销售预测版本', prop: 'fcstVersionName' },
  { label: '缺货库存总量', prop: 'shortageNumber' },
  { label: '可调出备货渠道', prop: 'outStockChannelName' },
  { label: '冗余数量', prop: 'redundantNumber' },
  { label: '仓库可用库存', prop: 'warehouseAvailableStock' },
  { label: '实际调出数量', prop: 'actualOutNumber', slot: 'actualOutNumber' }
]

export const allocatConfirmColumns = [
  { label: '序号', slot: 'newIndex' },
  { label: '仓库', prop: 'warehouseName' },
  { label: 'Style', prop: 'styleName' },
  { label: 'Color', prop: 'color' },
  { label: 'Size', prop: 'size' },
  { label: 'Sku', prop: 'sku' },
  { label: '调入备货渠道', prop: 'inStockChannelName' },
  { label: 'TurnOver(天)', prop: 'turnover' },
  { label: '库存汇总', prop: 'totalStock' },
  { label: '预测销售数量', prop: 'fcstNumber' },
  { label: '销售预测版本', prop: 'fcstVersionName' },
  { label: '缺货库存总量', prop: 'shortageNumber' },
  { label: '可调出备货渠道', prop: 'outStockChannelName' },
  { label: '冗余数量', prop: 'redundantNumber' },
  { label: '仓库可用库存', prop: 'warehouseAvailableStock' },
  { label: '实际调出数量', prop: 'actualOutNumber' }
]
export const columns = [
  { label: '类别', prop: 'categoryName', width: 240, formatter: (row) => {
    return row.categoryName && JSON.parse(row.categoryName)?.join('>')
  } },
  { label: '品牌', prop: 'brandName', width: 120 },
  { label: 'Style', prop: 'styleName', width: 120 },
  { label: '备货渠道', prop: 'stockChannelName', width: 120 },
  { label: '平台/站点/店铺', width: 200,
    formatter: (row) => {
      return `${row.platformName}/${row.siteName}/${row.shopName}`
    }
  },
  { label: '季节', prop: 'seasonName' },
  { label: '定位', prop: 'position' },
  { label: 'TurnOver', prop: 'stockAllocTurnover', width: 100 },
  { label: '海外自管仓可用库存', prop: 'overseasSelfAvlStock', width: 150 },
  { label: '海外自管仓在途库存', prop: 'overseasSelfTransStock', width: 150 },
  { label: '海外三方仓可用库存', prop: 'overseasThirdAvlStock', width: 150 },
  { label: '海外三方仓在途库存', prop: 'overseasThirdTransStock', width: 150 },
  { label: '海外平台仓可用库存', prop: 'overseasPlatAvlStock', width: 150 },
  { label: '海外平台仓在途库存', prop: 'overseasPlatTransStock', width: 150 },
  { label: '海外仓未发库存', prop: 'overseasNotSendStock', width: 150 },
  { label: '国内自管仓可用库存', prop: 'cnAvlStock', width: 150 },
  { label: '国内自管仓未发库存', prop: 'cnNotSendStock', width: 150 },
  { label: '调拨未结数量', prop: 'transUncleared', width: 130 },
  { label: '库存汇总', prop: 'totalStock' },
  { label: '预测销售数量', prop: 'fcstNumber', width: 130 },
  { label: '销售退货', prop: 'saleReturnNumber', width: 130 },
  { label: 'PO未结', prop: 'poUncleared', width: 130 },
  { label: '销售预测版本', prop: 'fcstVersionName', width: 120 },
  { label: '缺货数量', slot: 'lackNumber' },
  { label: '是否可调配', prop: 'canProvisionFlag', width: 120 },
  { label: '操作', slot: 'operate' }

]

export const expandColumns = [
  { label: 'Color', prop: 'color' },
  { label: 'Size', prop: 'size' },
  { label: 'Sku', prop: 'sku', width: 220 },
  { label: '定位', prop: 'position' },
  { label: 'TurnOver', prop: 'stockAllocTurnover' },
  { label: '海外自管仓可用库存', prop: 'overseasSelfAvlStock' },
  { label: '海外自管仓在途库存', prop: 'overseasSelfTransStock' },
  { label: '海外三方仓可用库存', prop: 'overseasThirdAvlStock' },
  { label: '海外三方仓在途库存', prop: 'overseasThirdTransStock' },
  { label: '海外平台仓可用库存', prop: 'overseasPlatAvlStock' },
  { label: '海外平台仓在途库存', prop: 'overseasPlatTransStock' },
  { label: '海外仓未发库存', prop: 'overseasNotSendStock' },
  { label: '国内自管仓可用库存', prop: 'cnAvlStock' },
  { label: '国内自管仓未发库存', prop: 'cnNotSendStock' },
  { label: '调拨未结数量', prop: 'transUncleared' },
  { label: '库存汇总', prop: 'totalStock' },
  { label: '预测销售数量', prop: 'fcstNumber' },
  { label: '销售退货', prop: 'saleReturnNumber', width: 130 },
  { label: 'PO未结', prop: 'poUncleared', width: 130 },
  { label: '销售预测版本', prop: 'fcstVersionName' },
  { label: '缺货数量', slot: 'stockoutNumber' },
  { label: '是否可调配', prop: 'canProvisionFlag' },
  { label: '操作', slot: 'operate' }
]

export const logColumns = [
  { label: '调拨单号', prop: 'transferOrderCode', width: 200 },
  { label: '调拨单状态', prop: 'stateI18', width: 120 },
  { label: '调配仓库', prop: 'provisionWarehouseName' },
  { label: 'Style', prop: 'styleName' },
  { label: 'Color', prop: 'color' },
  { label: 'Size', prop: 'size' },
  { label: 'Sku', prop: 'sku' },
  { label: '季节', prop: 'seasonName' },
  { label: '定位', prop: 'positionName' },
  { label: '调入备货渠道', prop: 'inStockChannelName' },
  { label: 'Turnover', prop: 'turnover' },
  { label: '海外自管仓可用库存', prop: 'overseasSelfAvlStock' },
  { label: '海外自管仓在途库存', prop: 'overseasSelfTransStock' },
  { label: '海外三方仓可用库存', prop: 'overseasThirdAvlStock' },
  { label: '海外三方仓在途库存', prop: 'overseasThirdTransStock' },
  { label: '海外平台仓可用库存', prop: 'overseasPlatAvlStock' },
  { label: '海外平台仓在途库存', prop: 'overseasPlatTransStock' },
  { label: '海外仓未发库存', prop: 'overseasNotSendStock' },
  { label: '国内自管仓可用库存', prop: 'cnAvlStock' },
  { label: '国内自管仓未发库存', prop: 'cnNotSendStock' },
  { label: '调拨单未结', prop: 'transUncleared' },
  { label: '库存汇总', prop: 'totalStock' },
  { label: '预测销售数量', prop: 'fcstNumber' },
  { label: '销售预测版本', prop: 'fcstVersionName' },
  { label: '缺货数量', prop: 'stockoutNumber' },
  { label: '调出备货渠道', prop: 'outStockChannelName' },
  { label: '调出数量', prop: 'outNumber' },
  { label: '操作人', prop: 'createByName' },
  { label: '操作时间', prop: 'createTime' }
]

export const transferColumns = [
  { label: '调拨单号', prop: 'transferOrderCode' },
  { label: '调拨类型', prop: 'transferTypeI18' },
  { label: '调出备货渠道', prop: 'outStockChannelName' },
  { label: '调入备货渠道', prop: 'inStockChannelName' },
  { label: '调出仓库', prop: 'outWarehouseName' },
  { label: '调入仓库', prop: 'inWarehouseName' },
  { label: '预计调拨数量', prop: 'expectTransferNumber' },
  { label: '备注',
    slot: 'remark'
  },
  { label: '提示信息', prop: 'partialTransferConfirmTip' }
]
