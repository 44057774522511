<template>
  <div>
    <el-steps :active="active" align-center class="mb-3">
      <el-step title="调配仓库确认" />
      <el-step title="调拨明细确认" />
      <el-step title="生成调拨单" />
    </el-steps>
    <section v-show="active === 1">
      <el-row type="flex" justify="space-between">
        <el-col :span="6"> 可调配仓库： </el-col>
        <el-col :span="6">
          <el-button
            type="text"
            @click="handleSuggestion"
          >调配建议生成</el-button>
        </el-col>
      </el-row>
      <el-tabs class="mt-3">
        <el-tab-pane
          v-for="(item, index) in StockProvisionViewResponseDTO"
          :key="index"
        >
          <div slot="label">
            <div style="display: flex">
              <div style="display: inline-block" class="mr-2">
                {{ item.warehouseName }}
              </div>
              <div style="color: #909399">
                <div v-for="(e, idx) in item.warehousetagList" :key="idx">
                  ● {{ e }}
                </div>
              </div>
            </div>
          </div>

          <Table
            :table-data="item.skuStockChannelList"
            max-height="600px"
            :show-index="false"
            :columns="allocatColumns"
            :border="true"
            :object-span-method="
              (e) =>
                objectSpanMethod(e, item.skuStockChannelList, allocatColumns)
            "
          >
            <el-table-column
              slot="newIndex"
              width="50px"
              label="序号"
              type="index"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.newIndex }}
              </template>
            </el-table-column>
            <el-table-column
              slot="actualOutNumber"
              width="180px"
              label="实际调出数量"
              prop="actualOutNumber"
            >
              <!--  -->
              <template slot-scope="scope">
                <el-input-number
                  ref="inputRef"
                  v-model="scope.row.actualOutNumber"
                  :controls="false"
                  :precision="0"
                  :min="0"
                  style="width: 150px"
                  :max="Math.min(scope.row.defaultWarehouseAvailableStock,scope.row.defaultRedundantNumber,scope.row.defaultShortageNumber)"
                  @blur="
                    handleBlur(
                      scope.row.actualOutNumber,
                      scope.row,
                      item,
                      StockProvisionViewResponseDTO
                    )
                  "
                />
              </template>
            </el-table-column>
          </Table>
        </el-tab-pane>
      </el-tabs>
    </section>
    <section v-show="active === 2" style="text-align: center">
      <Table
        :table-data="comfirmStockChannelList"
        max-height="600px"
        :show-index="false"
        :columns="allocatConfirmColumns"
        :border="true"
        :object-span-method="
          (e) =>
            objectSpanMethod(e, comfirmStockChannelList, allocatConfirmColumns)
        "
      />
    </section>
    <section v-show="active === 3" style="text-align: center">
      <Table
        :table-data="transferList"
        max-height="600px"
        :columns="transferColumns"
        :border="true"
      >
        <el-table-column slot="remark" width="180px" label="备注">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.remark"
              type="textarea"
              :rows="2"
              :maxlength="256"
              show-word-limit
              style="width: 120px"
            />
          </template>
        </el-table-column>
      </Table>
    </section>
    <section v-show="active === 4" style="text-align: center;">
      <el-result icon="success" title="成功生成调拨单">
        <template slot="extra">
          <div style="width:80%; word-break:break-all;">
            调拨单号：
            <router-link
              v-for="(item, index) in transterCodeList"
              :key="index"
              class="mr-2 mb-2"
              :to="{
                path: '/replenishment-allocation-management/transfer-order-management/details',
                query: { transferOrderCode: item },
              }"
              style="color: #1890ff;"
            >{{ item }}</router-link>
          </div>
          <el-button class="mt-4" @click="handleDone">完成</el-button>
        </template>
      </el-result>
    </section>

    <div v-if="active < 4" style="display: flex; justify-content: center">
      <el-button @click="handleCancel">{{
        active === 1 ? "取消" : "上一步"
      }}</el-button>
      <el-button v-if="active === 1" @click="handleReset">重置</el-button>
      <el-button type="primary" @click="handleNext">{{ titleName }}</el-button>
    </div>
  </div>
</template>
<script>
import {
  allocatColumns,
  transferColumns,
  allocatConfirmColumns
} from '../../columns'
import Table from '@/components/Table'
import {
  createTransferOrderView,
  provisionView,
  createTransferOrderSubmit,
  provisionSuggestView
} from '@/api/inventory-allocation'
import { omit } from 'lodash'
import NP from 'number-precision'
import { cloneDeep } from 'lodash'
import { handleBreadTitle, trackBtnClickEvent } from '@/utils/monitor'
export default {
  components: { Table },
  data() {
    return {
      transterCodeList: [],
      active: 1,
      allocatColumns,
      transferColumns,
      comfirmStockChannelList: [],
      transferList: [],
      StockProvisionViewResponseDTO: [],
      allocatConfirmColumns,
      provisionViewParams: {},
      provisionView,
      provisionSuggestView
    }
  },
  computed: {
    titleName() {
      return [1, 2].includes(this.active) ? '下一步' : '提交'
    }
  },

  mounted() {
    this.provisionViewParams =
      JSON.parse(sessionStorage.getItem('provisionViewParams')) || {}
    this.handleQuery(provisionView)
  },
  methods: {
    handleSuggestion() {
      trackBtnClickEvent(handleBreadTitle(this.$route) + '建议生成')
      this.handleQuery(provisionSuggestView)
    },
    async handleQuery(api) {
      const { provisionCycle, indicatorList } = this.provisionViewParams
      const skuStockChannelList = indicatorList?.map((v) => {
        return {
          platformId: v.platformId,
          shopId: v.shopId,
          siteId: v.siteId,
          sku: v.sku,
          stockChannelId: v.stockChannelId
        }
      })
      const { datas } = await api({ provisionCycle, skuStockChannelList })
      this.StockProvisionViewResponseDTO = datas?.map((item) => {
        // 平铺数组 合并行  a*b项
        const skuStockChannelList = item.skuStockChannelList.map((sku, idx) => {
          sku.outList = sku?.outList?.map((v) => {
            return {
              ...omit(sku, 'outList'),
              ...v,
              newIndex: idx + 1,
              defaultShortageNumber: sku.shortageNumberOrigin || sku.shortageNumber,
              defaultWarehouseAvailableStock: v.warehouseAvailableStockOrigin || v.warehouseAvailableStock,
              defaultRedundantNumber: v.redundantNumberOrigin || v.redundantNumber,
              warehouseName: item.warehouseName,
              warehouseCode: item.warehouseCode
            }
          })
          return sku.outList
        })
        return { ...item, skuStockChannelList: skuStockChannelList.flat() }
      })
      // console.log('this.StockProvisionViewResponseDTO: ', this.StockProvisionViewResponseDTO)
    },
    handleReset() {
      this.$confirm('该操作将重置并刷新页面数据，是否继续?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.handleQuery(provisionView)
      })
    },

    handleBlur(e, row, { skuStockChannelList: list, warehouseName }, allList) {
      if (!e) {
        row.actualOutNumber = 0
        e = 0
      }
      if (NP.minus(e, row.defaultWarehouseAvailableStock) > 0) {
        return this.$message.warning('实际调出数量不能大于仓库可用库存')
      }
      if (NP.minus(e, row.defaultRedundantNumber) > 0) {
        return this.$message.warning('实际调出数量不能大于冗余库存数量')
      }
      if (NP.minus(e, row.defaultShortageNumber) > 0) {
        return this.$message.warning('实际调出数量不能大于缺货库存数量')
      }
      row.warehouseAvailableStock = NP.minus(
        row.defaultWarehouseAvailableStock,
        e
      )
      const arr = []
      let totalActualNumber = 0
      let totalRedundantNumber = 0
      allList = allList.map((item) => {
        item.skuStockChannelList.map((v) => {
          arr.push(v)
          return { ...v }
        })
        return { ...item }
      })

      arr
        .filter(
          (v) =>
            row.sku === v.sku && row.inStockChannelName === v.inStockChannelName
        )
        .map((v) => {
          totalActualNumber = NP.plus(totalActualNumber, v.actualOutNumber)
        })
      arr
        .filter(
          (v) =>
            row.sku === v.sku &&
            row.outStockChannelName === v.outStockChannelName
        )
        .map((v) => {
          totalRedundantNumber = NP.plus(
            totalRedundantNumber,
            v.actualOutNumber
          )
        })
      row.totalActualNumber = totalActualNumber
      row.totalRedundantNumber = totalRedundantNumber
      allList = allList.map((item) => {
        item.skuStockChannelList.map((v) => {
          if (
            v.sku === row.sku &&
            v.inStockChannelName === row.inStockChannelName
          ) {
            v.shortageNumber = NP.minus(
              v.defaultShortageNumber,
              row.totalActualNumber
            )
          }
          if (
            v.sku === row.sku &&
            v.outStockChannelName === row.outStockChannelName
          ) {
            v.redundantNumber = NP.minus(
              v.defaultRedundantNumber,
              row.totalRedundantNumber
            )
          }
          return { ...v }
        })
        return { ...item }
      })
    },
    objectSpanMethod(
      { row, column, rowIndex, columnIndex },
      skuStockChannelList,
      defineCcolumns
    ) {
      const list = [
        'outStockChannelName',
        'redundantNumber',
        'warehouseAvailableStock',
        'actualOutNumber'
      ]
      const fields = defineCcolumns
        .map((v) => v.prop)
        .filter((v) => !list.includes(v))
      if (fields.includes(column.property)) {
        const cellValue = row['sku']
        const cellChannelValue = row['inStockChannelName']
        const cellWarehouseValue = row['warehouseName']
        if (cellValue && fields.includes(column.property)) {
          const prevRow = skuStockChannelList[rowIndex - 1]
          let nextRow = skuStockChannelList[rowIndex + 1]
          if (
            prevRow &&
            prevRow['sku'] === cellValue &&
            prevRow['warehouseName'] === cellWarehouseValue &&
            cellChannelValue === prevRow['inStockChannelName']
          ) {
            return { rowspan: 0, colspan: 0 }
          } else {
            // return { rowspan: row.rowspan, colspan: 1 }
            let countRowspan = 1
            while (
              nextRow &&
              nextRow['sku'] === cellValue &&
              nextRow['warehouseName'] === cellWarehouseValue &&
              cellChannelValue === nextRow['inStockChannelName']
            ) {
              nextRow = skuStockChannelList[++countRowspan + rowIndex]
            }
            if (countRowspan > 1) {
              return { rowspan: countRowspan, colspan: 1 }
            }
          }
        }
      }
    },
    handleParams(arr) {
      // 定义一个用来存已经分好类数据的数组
      var arr2 = []
      // 遍历数组
      for (var i = 0; i < arr.length; i++) {
        const { actualOutNumber, outStockChannelId, outStockChannelName } =
          arr[i]
        // 判断新定义的数组有没有值  没有值则减第一个类给他赋值上去
        if (arr2.length === 0) {
          arr2.push({
            ...arr[i],
            outList: [
              { actualOutNumber, outStockChannelId, outStockChannelName }
            ]
          })
        } else {
          // 第一个完后 后面的有值了 使用findindex()方法进行比较 存在返回下标index 不存在则返回-1
          var index = arr2.findIndex((item) => {
            return (
              item.sku === arr[i].sku &&
              item.inStockChannelName === arr[i].inStockChannelName &&
              item.warehouseCode === arr[i].warehouseCode
            )
          })
          // 判断index是否大于0 大于0则在该类的data push进该类别的数据
          if (index >= 0) {
            arr2[index].outList.push({
              actualOutNumber,
              outStockChannelId,
              outStockChannelName
            })
          } else {
            // 不存在则新建一个对象  push 进arr2 里面 作为第二个类
            arr2.push({
              ...arr[i],
              outList: [
                { actualOutNumber, outStockChannelId, outStockChannelName }
              ]
            })
          }
        }
      }
      return arr2
    },
    async handleNext() {
      if (this.active === 1) {
        this.comfirmStockChannelList = []
        const list = cloneDeep(this.StockProvisionViewResponseDTO)
        list?.map((item) => {
          item.skuStockChannelList?.map((v) => {
            v.actualOutNumber && this.comfirmStockChannelList.push({ ...v })
          })
        })
        if (!this.comfirmStockChannelList.length) {
          return this.$message.warning('至少填写一条实际调出数量')
        }
        return this.active++
      }
      if (this.active === 2) {
        const list = this.handleParams(this.comfirmStockChannelList)
        const { datas } = await createTransferOrderView(list)
        this.transferList = datas
        return this.active++
      }
      if (this.active === 3) {
        // 第一次提交有错误提示返回，再确认提交一次
        this.handleSubmit()
      }
    },
    async handleSubmit() {
      const params = {}
      const confirmPartialTransferFlag = !!this.transferList.some(
        (v) => v.partialTransferConfirmTip
      )
      Object.assign(params, {
        confirmPartialTransferFlag,
        transferOrderList: this.transferList,
        indicatorList: this.provisionViewParams.indicatorList
      })
      const {
        datas: { tipList = [] }
      } = await createTransferOrderSubmit(params)
      if (!tipList || !tipList.length) {
        this.transterCodeList = this.transferList.map(
          (v) => v.transferOrderCode
        )
        this.active++
      } else {
        // 需再次提交
        this.transferList = this.transferList.map((v) => {
          return {
            ...v,
            partialTransferConfirmTip: tipList.find(
              (vv) => vv.transferOrderCode === v.transferOrderCode
            )?.partialTransferConfirmTip
          }
        })
      }
    },
    handleDone() {
      this.$router.go(-1)
      sessionStorage.removeItem('provisionViewParams')
    },
    handleCancel() {
      if (this.active !== 1) {
        this.active--
      } else {
        this.handleDone()
      }
    }
  }
}
</script>
<style scoped lang="scss">
/deep/ .el-tabs__header .is-top {
  height: auto;
}
</style>
